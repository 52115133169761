import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'

export function checkNullableDate(date) {
  return DateTime.fromISO(date).toFormat('HHmm') !== '0000'
}

export function startFeelingGiven(feeling) {
  return !isEmpty(feeling)
}

export function endFeelingGiven(feeling) {
  return (
    !isEmpty(feeling?.endEmotion) ||
    !isEmpty(feeling?.endThought) ||
    !isEmpty(feeling?.endPhysicalLevels)
  )
}
