import ForgotPasswordForm from 'components/organisms/ForgotPasswordForm'
import ForgotPasswordSuccess from 'components/organisms/ForgotPasswordSuccess'
import MailFusionFieldsForm from 'components/organisms/MailFusionFieldsForm'
import MailFusionFieldsManager from 'components/organisms/MailFusionFieldsManager'
import MailTemplateForm from 'components/organisms/MailTemplateForm'
import MailTemplatesManager from 'components/organisms/MailTemplatesManager'
import EmailTemplateEdit from 'components/organisms/MailTemplatesManager/components/Edit'
import UsersForm from 'components/organisms/UsersForm'
import UsersManager from 'components/organisms/UsersManager'
import UsersEdit from 'components/organisms/UsersManager/components/Edit'
import App from 'components/routes/App'
import AppParams from 'components/routes/AppParams'
import Customers from 'components/routes/Customers'
import FieldsParams from 'components/routes/FieldsParams'
import ForgotPassword from 'components/routes/ForgotPassword'
import Home from 'components/routes/Home'
import Historic from 'components/routes/Historic'
import Internationalization from 'components/routes/Internationalization'
import LevelDefaults from 'components/routes/LevelDefaults'
import Login from 'components/routes/Login'
import Logout from 'components/routes/Logout'
import MailManagement from 'components/routes/MailManagement'
import NotFound from 'components/routes/NotFound'
import QuestionsAnswers from 'components/routes/QuestionsAnswers'
import ResetPassword from 'components/routes/ResetPassword'
import Runs from 'components/routes/Runs'
import RunCreate from 'components/routes/Runs/Create'
import RunEdit from 'components/routes/Runs/Edit'
import ThematicsIndex from 'components/routes/Thematics'
import ThematicsCreate from 'components/routes/Thematics/Create'
import ThematicsEdit from 'components/routes/Thematics/Edit'
import RunDashboard from 'components/routes/Historic/UserRunDashboard'
import UnderConstruction from 'components/routes/UnderConstruction'
import Users from 'components/routes/Users'
import { useAuth } from 'contexts/AuthContext'
import { APP_PARAMS_PATH, EMAIL_MANAGEMENT_TEMPLATES_PATH } from 'enums/paths'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { DefaultRole } from 'domains/roles/enums'
import Partners from 'components/routes/Partners'

function RequireAuth({ children }) {
  const { user } = useAuth()
  const location = useLocation()

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

function RequireRights({ children }) {
  const { is, loading } = useAuth()
  const location = useLocation()
  if (!is(DefaultRole.ADMIN) && !is(DefaultRole.SUPERVISOR) && !loading) {
    return <Navigate to="/not-found" state={{ from: location }} replace />
  }
  return children
}

const routes = [
  {
    path: '',
    element: (
      <RequireAuth>
        <App />
      </RequireAuth>
    ),
    children: [
      { index: true, element: <Home /> },
      {
        path: 'historic',
        children: [
          {
            index: true,
            element: (
              <RequireAuth>
                <Historic />
              </RequireAuth>
            ),
          },
          {
            path: ':id',
            element: (
              <RequireAuth>
                <RunDashboard />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: 'thematics',
        children: [
          {
            index: true,
            element: (
              <RequireRights>
                <ThematicsIndex />
              </RequireRights>
            ),
          },
          {
            path: 'edit/:id',
            element: (
              <RequireRights>
                <ThematicsEdit />
              </RequireRights>
            ),
          },
          {
            path: 'create',
            element: (
              <RequireRights>
                <ThematicsCreate />
              </RequireRights>
            ),
          },
        ],
      },
      {
        path: 'runs',
        children: [
          {
            index: true,
            element: (
              <RequireRights>
                <Runs />
              </RequireRights>
            ),
          },
          {
            path: 'create',
            element: (
              <RequireRights>
                <RunCreate />
              </RequireRights>
            ),
          },
          {
            path: 'edit/:id',
            element: (
              <RequireRights>
                <RunEdit />
              </RequireRights>
            ),
          },
        ],
      },
      {
        path: 'customers',
        element: (
          <RequireRights>
            <Customers />
          </RequireRights>
        ),
      },
      {
        path: 'partners',
        element: (
          <RequireRights>
            <Partners />
          </RequireRights>
        ),
      },
      {
        path: 'level-defaults',
        element: (
          <RequireRights>
            <LevelDefaults />
          </RequireRights>
        ),
      },
      {
        path: 'questions-answers',
        element: (
          <RequireRights>
            <QuestionsAnswers />
          </RequireRights>
        ),
      },
      {
        path: 'app-params',
        element: (
          <RequireRights>
            <AppParams />
          </RequireRights>
        ),
        children: [
          {
            index: true,
            element: (
              <RequireRights>
                <Navigate to={APP_PARAMS_PATH} />
              </RequireRights>
            ),
          },
          {
            path: 'app-parameters',
            element: (
              <RequireRights>
                <MailTemplatesManager />
              </RequireRights>
            ),
          },
        ],
      },
      {
        path: 'email-management',
        element: (
          <RequireRights>
            <MailManagement />
          </RequireRights>
        ),
        children: [
          {
            index: true,
            element: (
              <RequireRights>
                <Navigate to={EMAIL_MANAGEMENT_TEMPLATES_PATH} />
              </RequireRights>
            ),
          },
          {
            path: 'templates',
            element: (
              <RequireRights>
                <MailTemplatesManager />
              </RequireRights>
            ),
          },
          {
            path: 'templates/create',
            element: (
              <RequireRights>
                <MailTemplateForm />
              </RequireRights>
            ),
          },
          {
            path: 'layout',
            element: (
              <RequireRights>
                <UnderConstruction />
              </RequireRights>
            ),
          },
          {
            path: 'fusion-fields',
            element: (
              <RequireRights>
                <MailFusionFieldsManager />
              </RequireRights>
            ),
          },
          {
            path: 'fusion-fields/create',
            element: (
              <RequireRights>
                <MailFusionFieldsForm />
              </RequireRights>
            ),
          },
          {
            path: 'test',
            element: (
              <RequireRights>
                <UnderConstruction />
              </RequireRights>
            ),
          },
          {
            path: '*',
            element: (
              <RequireRights>
                <Navigate to={EMAIL_MANAGEMENT_TEMPLATES_PATH} />
              </RequireRights>
            ),
          },
        ],
      },
      {
        path: 'email-management/templates/:id/edit',
        element: (
          <RequireRights>
            <EmailTemplateEdit />
          </RequireRights>
        ),
      },
      {
        path: 'internationalization',
        element: (
          <RequireRights>
            <Internationalization />
          </RequireRights>
        ),
      },
      {
        path: 'users',
        element: (
          <RequireRights>
            <Users />
          </RequireRights>
        ),
        children: [
          {
            index: true,
            element: (
              <RequireRights>
                <UsersManager />
              </RequireRights>
            ),
          },
          {
            path: 'create',
            element: (
              <RequireRights>
                <UsersForm />
              </RequireRights>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <RequireRights>
                <UsersEdit />
              </RequireRights>
            ),
          },
        ],
      },
      {
        path: 'user-fields-parameter',
        element: (
          <RequireRights>
            <FieldsParams />
          </RequireRights>
        ),
      },
      { path: '*', element: <NotFound /> },
    ],
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'logout',
    element: <Logout />,
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
    children: [
      {
        index: true,
        element: <ForgotPasswordForm />,
      },
      {
        path: 'success',
        element: <ForgotPasswordSuccess />,
      },
    ],
  },
  {
    path: 'reset-password/:key',
    element: <ResetPassword />,
  },
  { path: '*', element: <NotFound /> },
]

export default routes
